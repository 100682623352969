import React from "react";

const ButtonPrimary = ({ children, addClass, type }) => {
  const button = type ? (
    <button
      type={type}
      className={
        "py-3 lg:py-4 px-12 lg:px-16 text-white font-semibold rounded-lg bg-brand hover:shadow-orange-md transition-all outline-none " +
        addClass
      }
    >
      {children}
    </button>
  ) : (
    <button
      className={
        "py-3 lg:py-4 px-12 lg:px-16 text-white font-semibold rounded-lg bg-brand hover:shadow-orange-md transition-all outline-none " +
        addClass
      }
    >
      {children}
    </button>
  );

  return button;
};

export default ButtonPrimary;
